<template>
  <div>
    <head-banner class="pb-2" />
    <AddHere />
    <b-row class="pt-2">
      <b-col md="3"> </b-col>
      <b-col cols="12" md="6">
        <b-card>
          <b-row class="text-center">
            <b-col>
              <h1><b> Simple tasks to earn extra money</b></h1>
            </b-col>
          </b-row>
          <b-row class="text-center pt-2">
            <b-col>
              <h4  class="card-text-style">
                Have some free hour? We provide an opportunity go earn some
                extra money. Paying daily in BNB for easy tasks, work on your
                own schedule and as much as you want.
              </h4>
            </b-col>
          </b-row>
          <b-row class="text-center pt-2">
            <b-col>
              <h3 >
                For more details, please contact our support <b><a href="mailto:contact@rugfreecoins.com">contact@rugfreecoins.com</a></b>
              </h3>
            </b-col>
          </b-row>
          <b-row class="text-center pt-2">
            <b-col>
              <b-img
                src="@/assets/images/logo/rugfreecoins.png"
                height="100"
                alt="RugFreeCoins"
              ></b-img>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BImg } from "bootstrap-vue";
import AddHere from "@/@core/components/AddHere.vue";
import HeadBanner from "@/@core/components/carousel/HeadBanner.vue"

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    AddHere,
    HeadBanner
  },
  computed: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style>
.card-body {
  padding: 4.5rem;
}
.card-text-style {
    font-weight: 300;
    line-height: 2.2;
}
</style>